export default {
    address: 'Address',
    agreementCodePlaceholder: 'ex. P12345678',
    city: 'City',
    companyName: 'Name',
    contactPerson: 'Contact person',
    country: 'Country',
    email: 'Email for confirmation',
    emailHelpText: 'You can enter multiple e-mails by using comma in between the addresses',
    enterAgreementCode: 'Enter agreement code',
    packages: 'Packages',
    palletNO: 'Pallet (max 1)',
    pallets: 'Pallets',
    phoneNumber: 'Phone number',
    phoneNumberHelpText: 'In case the driver needs contact with the person who will hand over the shipments',
    pickupAddress: 'Pickup address',
    pickupDate: 'Pickup date',
    pickupDateInfoEarliest: 'Pickup may be ordered for %{date} at the earliest.',
    pickupDateInfoSameDay: 'For same day pickup, make sure to place order before %{time}.',
    pickupDateInfoTime: 'Pickup time for selected date (%{date}) will normally be between %{earliest} and %{latest}.',
    pickupInfo: 'Pickup information for the driver',
    pickupInfoHelpText: 'Floor number, directions etc.',
    pickupWhat: 'What will be picked up?',
    pickupWhen: 'When is it ready for pickup?',
    pickupWhere: 'Where should it be picked up?',
    postContainer: 'Post-container (max 1)',
    postalCode: 'Postal code',
    searchButton: 'Search',
    submitButton: 'Book pickup',
    totalWeight: 'Total weight',
    weightInfoText: 'Maximum weight for a single parcel is 35 kg',
    confirmation: {
        confirmationMail: 'Confirmation sent to',
        labelNotification: 'NB: All items must be correctly labelled when they are picked up.',
        multipleContainer: '%{number} containers',
        multipleItemPickup: '%{number} items for pickup',
        multiplePackage: '%{number} packages',
        multiplePallet: '%{number} pallets',
        packageAndBusinessPalletPickup: 'Package and Business Pallet Pickup',
        packageNumber: 'Order number',
        pickup: 'Pickup',
        pickupString: '%{formattedDate} between %{earliestTime} and %{latestTime}',
        pickupAddress: 'Pickup address',
        service: 'Service',
        singleContainer: '1 container',
        singleItemPickup: 'One item for pickup',
        singlePackage: '1 package',
        singlePallet: '1 pallet',
        swedenWarning: 'Note: in some geographical areas, desired pickup the same day can not be guaranteed even though the booking is made by 12:00.',
    },
    error: {
        couldNotReachSgWarning: 'We are not able to confirm available pickup dates for the chosen postal code! You may still pick a pickup date, but be aware we can may not be able to pick up the order at the selected date.',
        dateMustBeValid: 'Selected date must be a valid date for pickups',
        failedSubmit: 'Pickup order failed',
        internalError: 'Internal Error',
        internalErrorInfo: 'Something failed while trying to retrieve the entered agreement code. Please try again, or contact customer support.',
        invalidEmail: 'Invalid email format',
        invalidPostalCode: 'Invalid postal code',
        missingItems: 'You must set at least one package or pallet',
        missingItemsNO: 'You must set at least one package or a post container or a pallet',
        notFound: 'Could not find agreement',
        notFoundInfo: 'Did you type the correct agreement code?',
        notFoundInfo2: 'A valid pickup agreement code always starts with P and consist of between 8 and 12 characters.',
        notFoundPostalCode: 'Unknown/invalid postal code',
        onlyDigitsAllowed: 'Input may only be digits',
        postalCodeRange: 'Postal code must be exactly %{MIN} digits',
        required: 'This field is required',
        stringRange: 'Must be between %{MIN} and %{MAX} characters',
        tooManyPackages: 'You may not have more than %{MAX} packages',
        tooManyPallets: 'You may not have more than %{MAX} pallets',
        warning: 'Warning',
        weightMissingToSmall: 'Weight is required as a positive number',
        weightTooHigh: 'Max total weight is %{MAX} kg',
    },
    hero: {
        title: 'Order new pickup',
    },
    nav: {
        gotoHomePage: 'Go to home page',
        selectLanguage: 'Set language',
    },
}
