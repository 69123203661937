import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './DatePicker.module.css'
import {I18n} from "../../common/i18n";
import {useFormContext} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {
    date2DDMMYYYY, date2HHMM,
    date2YYYYMMDD,
    isNullOrEmpty,
    notNullOrEmpty,
    shallowUnequal
} from "../../common/utils";
import {ShippingGuideRequestObject, useSG, ValidPickupDate} from "../../hooks/useSG";

const DISPLAY_DATE_FORMAT = 'dd.MM.yyyy'

interface Props {
    pickupDate?: Date
    earliestPickupDate?: Date
    countryCode: string
    postalCode: string
    pickupProduct: string
}

const isValidRequestObject = (ro: ShippingGuideRequestObject | null) =>
    ![ro, ro?.country, ro?.postalCode, ro?.pickupProduct].some(isNullOrEmpty)

const pickupProduct2SgProduct = (pickupProduct: string, countryCode: string): string => {
    if (pickupProduct == 'PARCEL'){
        if(countryCode == 'NO') return 'PICKUP_PARCELS_NEXTDAY'
        return 'PICKUP_PARCELS_INTERNATIONAL'
    }
    return 'CARGO'
}

export const PickupDate = ({
                               pickupDate: initialPickupDate,
                               countryCode,
                               postalCode,
                               pickupProduct
                           }: Props) => {
    console.log('render pickupDate', countryCode, postalCode)
    const i18n = I18n.i18n()
    const t = I18n.t

    const {register, getValues, setValue, formState: {errors}} = useFormContext()
    const [pickupDate, setPickupDate] = useState<Date>(initialPickupDate || new Date())
    const [validPickupDateStrings, setValidPickupDateStrings] = useState<String[]>([])
    const [requestObject, setRequestObject] = useState<ShippingGuideRequestObject | null>(null)
    const [sgResponseObject, setSgResponseObject] = useState<ValidPickupDate[]>([])

    const {isError, isLoading, sgData} = useSG(requestObject, isValidRequestObject(requestObject))

    const filterDate = (d: Date): boolean => validPickupDateStrings.includes(date2YYYYMMDD(d))

    useEffect(() => {
        if (notNullOrEmpty(postalCode) && notNullOrEmpty(countryCode) && isNullOrEmpty(errors?.postalCode)) {
            const request: ShippingGuideRequestObject = {
                country: countryCode,
                postalCode: postalCode,
                pickupProduct: pickupProduct2SgProduct(pickupProduct, countryCode),
            }
            if (shallowUnequal(request, requestObject) && !isLoading) {
                setRequestObject(request)
            }
        }
    }, [countryCode, postalCode])


    useEffect(() => {
        if (!isError && notNullOrEmpty(sgData)) {
            setSgResponseObject(sgData)
        }

        if (notNullOrEmpty(sgData?.[0]?.earliestPickupISO)) {
            const validDateStrings = sgData?.map(vpd =>
                vpd.earliestPickupISO?.slice(0, 10) || ''
            ).sort() || []
            setValidPickupDateStrings(validDateStrings)
        } else if (isError || notNullOrEmpty(sgData?.[0]?.codeType)) {
            const date = new Date()
            let dateArray = [...Array(10)].map(()=>{
                date.setDate(date.getDate() + 1)
                return date2YYYYMMDD(date)
            })
            setValidPickupDateStrings(dateArray)
        }
    }, [sgData])

    useEffect(() => {
        if (!isNullOrEmpty(pickupDate) && pickupDate != getValues('pickupDate'))
            setValue('pickupDate', pickupDate)
    }, [pickupDate])

    const pickupDateInfo = () => {
        if (isLoading) return ''
        else if (isError || notNullOrEmpty(sgResponseObject?.[0]?.codeType)) {
            return <div className="hw-warning-box override">
                <div className={'hw-warning-box__body'}>
                    <div className={'hw-warning-box__title'}>{i18n.error.warning}</div>
                    {i18n.error.couldNotReachSgWarning}
                </div>
            </div>
        }
        else if (!isError && notNullOrEmpty(sgResponseObject) && notNullOrEmpty(pickupDate)) {
            const earliestPickupDate = new Date(Date.parse(sgResponseObject[0].earliestPickupISO || ''))

            const validPickupDate = sgResponseObject.find(vpd => vpd.earliestPickupISO?.slice(0, 10) == pickupDate.toISOString().slice(0, 10))
            let timeRangeInfo = <></>
            let sameDayInfo = <></>
            if (validPickupDate) {
                const earliestPickup = new Date(Date.parse(validPickupDate.earliestPickupISO || ''))
                const latestPickup = new Date(Date.parse(validPickupDate.latestPickupISO || ''))
                timeRangeInfo = <li>{t('pickupDateInfoTime', {
                    date: date2DDMMYYYY(earliestPickup),
                    earliest: date2HHMM(earliestPickup),
                    latest: date2HHMM(latestPickup)
                })}</li>
            }
            if (['SE', 'DK'].includes(countryCode)) {
                sameDayInfo = <li>{t('pickupDateInfoSameDay', {time: countryCode == 'DK' ? '10:00' : '12:00'})}</li>
            }

            return <div className="hw-info-subtle override">
                <ul>
                    <li>{t('pickupDateInfoEarliest', {date: date2DDMMYYYY(earliestPickupDate)})}</li>
                    {timeRangeInfo}
                    {sameDayInfo}
                </ul>
            </div>
        }
        return ''
    }

    const validateDate = (value: string) => {
        if (notNullOrEmpty(validPickupDateStrings)) {
            const date = new Date(Date.parse(value))
            return validPickupDateStrings.includes(date2YYYYMMDD(date)) || i18n.error.dateMustBeValid
        }
        return true
    }

    return <div className={'date-picker__wrapper'}>
        <input type='hidden'  {...register("pickupDate", {
            required: {
                value: true,
                message: i18n.error.required
            },
            validate: validateDate
        })}/>
        <label className={`hw-label hw-datepicker ${errors.pickuDate && 'hw-label--error'}`}>
            {i18n.pickupDate}
            <div className={`hw-datepicker__inner ${errors.pickupDate && 'hw-input--error'}`}>
                <div>
                    {isLoading && <div className='hw-input hw-button__spinner hw-button__spinner-dark fake-disabled'/>}
                    {!isLoading &&
                        <DatePicker
                            className={'hw-input'}
                            dateFormat={DISPLAY_DATE_FORMAT}
                            selected={pickupDate}
                            filterDate={filterDate}
                            onChange={(date) => {
                                if (!isNullOrEmpty(date)) {
                                    setPickupDate(date as Date)
                                }
                            }}
                            calendarStartDay={1}
                        />}
                </div>

                <i className="hw-datepicker__icon fas fa-calendar-alt"> </i>
            </div>
            {(errors.pickupDate) &&
                <span className='hw-error hw-error--align-left hw-error--indented'>{errors.pickupDate.message}</span>}
            {pickupDateInfo()}
        </label>
    </div>

}
