import React from 'react'
import {useFormContext} from "react-hook-form";
import {I18n} from "../../common/i18n";
import {RequiredAstrisk} from "../RequiredAstrisk";

interface Props {
    countryCode: string
}

function PackagesContainerPallets({countryCode}: Props) {
    const i18n = I18n.i18n()

    const {register, clearErrors, formState: {errors}, getValues, setError, trigger} = useFormContext()

    const isNorway = countryCode === 'NO'

    const validatePackages = (value: string) => {
        if (!isNorway) trigger('totalWeight')

        if (!/^\d*$/.test(value)) {
            return i18n.error.onlyDigitsAllowed
        }
        if (parseInt(value as string, 10) > 20) {
            return I18n.t('error.tooManyPackages', {MAX: 20})
        }
        return true
    }

    const validatePallets = (value: string) => {
        if (isNorway) return true
        if (!isNorway) trigger('totalWeight')

        if (!/^\d*$/.test(value)) {
            return i18n.error.onlyDigitsAllowed
        }
        if (parseInt(value as string, 10) > 20) {
            return I18n.t('error.tooManyPallets', {MAX: 20}) // TODO: get a real maxval
        }
        return true
    }

    const validateItems = () => {
        const itemCount = parseInt(getValues('packages') || '0', 10)
            + parseInt(getValues('postContainer') || '0', 10)
            + parseInt(getValues(isNorway ? 'palletsNO' : 'pallets') || '0', 10)
        if (itemCount < 1) {
            setError('missingItems', {
                type: 'manual',
                message: isNorway ? i18n.error.missingItemsNO : i18n.error.missingItems
            })
        } else {
            clearErrors('missingItems')
        }
        return true
    }

    const validateWeight = (value: string) => {
        if (isNorway) return true
        const sanitized = parseFloat(value.trim().replace(',', '.') || '0')
        if (sanitized <= 0) return I18n.i18n().error.weightMissingToSmall
        if (sanitized > 999) return I18n.t('error.weightTooHigh', {MAX: 999}) // TODO: set proper max weight

        // Total weight validation
        const packages = parseInt(getValues('packages'), 10)
        const pallets = parseInt(getValues('pallets'), 10)
        if (packages && !pallets && sanitized > (packages * 35)) return I18n.i18n().weightInfoText + '!'
        return true
    }

    const norwayInput = <>
        <div className={'hw-fieldset__content'}>
            <div className="hw-grid">
                <div className="hw-grid__item hw-one-half">
                    <label
                        className={`hw-label ${(errors.packages || errors.missingItems) ? 'hw-label--error' : ''}`}>
                        {i18n.packages}
                        <input
                            {...register("packages", {
                                validate: {
                                    validatePackages: validatePackages,
                                    validateItems: validateItems,
                                }
                            })}
                            className={`hw-input ${(errors.packages || errors.missingItems) ? 'hw-input--error' : ''}`}
                            type='text'
                            maxLength={2}
                            size={4}
                            style={{width: '50%'}}
                            pattern={'[0-9.]+'}
                        />
                    </label>
                </div>
                <div className="hw-grid__item hw-one-half">
                    <label className={`hw-checkbox ${(errors.missingItems) ? 'hw-checkbox--error' : ''}`}
                           style={{marginTop: "1em"}}>
                        <span>{i18n.postContainer}</span>
                        <input
                            {...register("postContainer", {
                                validate: validateItems
                            })}
                            type='checkbox'
                            value={1}
                        />
                        <i className="hw-checkbox__indicator"> </i>
                    </label>
                    <label className={`hw-checkbox ${(errors.missingItems) ? 'hw-checkbox--error' : ''}`}>
                        <span>{i18n.palletNO}</span>
                        <input
                            type='checkbox'
                            {...register("palletsNO", {
                                validate: validateItems
                            })}
                            key={'palletsNO'}
                            className='hw-input'
                            value={1}
                        />
                        <i className="hw-checkbox__indicator"> </i>
                    </label>
                </div>
            </div>
        </div>
        {errors.packages &&
            <div
                className='hw-error hw-error--align-left hw-error--indented override'>{errors.packages.message}</div>}
        {errors.missingItems &&
            <div
                className='hw-error hw-error--align-left hw-error--indented override'>{errors.missingItems.message}</div>}
    </>

    const notNorwayInput = <>
        <div className={'hw-fieldset__content'}>
            <div className="hw-grid">
                <div className="hw-grid__item hw-medium--one-third hw-medium--one-half">
                    <label
                        className={`hw-label ${(errors.packages || errors.missingItems) ? 'hw-label--error' : ''}`}>
                        {i18n.packages}
                        <input
                            {...register("packages", {
                                validate: {
                                    validatePackages: validatePackages,
                                    validateItems: validateItems,
                                }
                            })}
                            className={`hw-input ${(errors.packages || errors.missingItems) ? 'hw-input--error' : ''}`}
                            type='text'
                            maxLength={2}
                            size={4}
                            style={{width: '50%'}}
                            pattern={'[0-9.]+'}
                        />
                        {errors.packages &&
                            <span
                                className='hw-error hw-error--align-left hw-error--indented override'>{errors.packages.message}</span>}
                        {errors.missingItems &&
                            <div
                                className='hw-error hw-error--align-left hw-error--indented override'>{errors.missingItems.message}</div>}
                    </label>
                </div>
                <div className="hw-grid__item hw-medium--one-third hw-medium--one-half">
                    <label
                        className={`hw-label ${(errors.pallets || errors.missingItems) ? 'hw-label--error' : ''}`}>
                        {i18n.pallets}
                        <input
                            {...register("pallets", {
                                validate: {
                                    validatePallets: validatePallets,
                                    validateItems: validateItems,
                                }
                            })}
                            key={'pallets'}
                            className={`hw-input ${(errors.pallets || errors.missingItems) ? 'hw-input--error' : ''}`}
                            type='text'
                            maxLength={2}
                            size={4}
                            style={{width: '50%'}}
                            pattern={'[0-9.]+'}
                        />
                        {errors.pallets &&
                            <span
                                className='hw-error hw-error--align-left hw-error--indented override'>{errors.pallets.message}</span>}
                        {errors.missingItems &&
                            <div
                                className='hw-error hw-error--align-left hw-error--indented override'>{errors.missingItems.message}</div>}
                    </label>
                </div>
                <div className="hw-grid__item hw-medium--one-third hw-medium--one-half">
                    <label
                        className={`hw-label ${(errors.totalWeight) ? 'hw-label--error' : ''}`}>
                        {i18n.totalWeight}
                        <input
                            {...register("totalWeight", {
                                validate: validateWeight
                            })}
                            className={`hw-input ${(errors.totalWeight) ? 'hw-input--error' : ''}`}
                            type='text'
                            maxLength={3}
                            size={4}
                            style={{width: '50%'}}
                            pattern={'[0-9.,]+'}
                        />
                        {errors.totalWeight &&
                            <span
                                className='hw-error hw-error--align-left hw-error--indented override'>{errors.totalWeight.message}</span>}
                    </label>
                </div>
            </div>
        </div>
    </>

    return (
        <fieldset className={'hw-fieldset'}>
            <legend className={'hw-fieldset__legend'}>{I18n.i18n().pickupWhat}<RequiredAstrisk/></legend>
            <div className={'hw-info-box override'}>{I18n.i18n().weightInfoText}</div>
            {isNorway ? norwayInput : notNorwayInput}
        </fieldset>

    )
}

export default PackagesContainerPallets
