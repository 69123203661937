export default {
    address: 'Adresse',
    agreementCodePlaceholder: 'eks. P12345678',
    city: 'Sted',
    companyName: 'Navn',
    contactPerson: 'Kontaktperson',
    country: 'Land',
    email: 'E-post for bekreftelse',
    emailHelpText: 'Du kan angi flere e-postadresser ved å bruke komma mellom adressene',
    enterAgreementCode: 'Skriv inn avtalekode',
    packages: 'Antall pakker',
    palletNO: 'Pall (maks 1)',
    pallets: 'Antall paller',
    phoneNumber: 'Telefonnummer',
    phoneNumberHelpText: 'I tilfelle sjåføren trenger å ta kontakt med den som skal overlevere sendingene',
    pickupAddress: 'Henteadresse',
    pickupDate: 'Opphentingsdato',
    pickupDateInfoEarliest: 'Henting kan tidligst bestilles for %{date}.',
    pickupDateInfoSameDay: 'For henting samme dag må bestillingen være gjort før kl %{time}.',
    pickupDateInfoTime: 'Hentetide for valgt dato (%{date}) er normalt mellom kl %{earliest} og %{latest}.',
    pickupInfo: 'Henteinformasjon for sjåfør',
    pickupInfoHelpText: 'Etasjenummer, veibeskrivelse osv.',
    pickupWhat: 'Hva skal vi hente?',
    pickupWhen: 'Når er sending klar for henting?',
    pickupWhere: 'Hvor skal vi hente?',
    postContainer: 'Postcontainer (maks 1)',
    postalCode: 'Postnummer',
    searchButton: 'Søk',
    submitButton: 'Bestill henting',
    totalWeight: 'Totalvekt',
    weightInfoText: 'Maks vekt for en enkeltpakke er 35 kg',
    confirmation: {
        confirmationMail: 'Bekreftelse sendt til',
        labelNotification: 'NB: Alle kolli må være påført etikett når de blir hentet.',
        multipleContainer: '%{number} postcontainere',
        multipleItemPickup: '%{number} enheter for henting',
        multiplePackage: '%{number} enkeltpakker',
        multiplePallet: '%{number} paller',
        packageAndBusinessPalletPickup: 'Package and Business Pallet Pickup',
        packageNumber: 'Bestillingsnummer',
        pickup: 'Henting',
        pickupString: '%{formattedDate} mellom %{earliestTime} og %{latestTime}',
        pickupAddress: 'Adresse for henting',
        service: 'Tjeneste',
        singleContainer: '1 postcontainer',
        singleItemPickup: 'En enhet for henting',
        singlePackage: '1 enkeltpakke',
        singlePallet: '1 pall',
        swedenWarning: 'Merk: i enkelte geografiske områder vil ønsket henting samme dag ikke kunne garanteres selv om bestillingen er utført innen 12:00.',
    },
    error: {
        couldNotReachSgWarning: 'Vi er ikke i stand til å bekrefte hentedato. Du kan fremdeles bestille, men vær klar over at vi muligens ikke kan hente på akkurat denne datoen.',
        dateMustBeValid: 'Valgt dato må være en dato som er gyldig for henting',
        failedSubmit: 'Henteordre feilet',
        internalError: 'Teknisk feil',
        internalErrorInfo: 'Noe feilet når vi forsøkte å verifisere hentekoden. Vennligst prøv igjen, eller kontakt kundeservice.',
        invalidEmail: 'Ugyldig e-postadresse',
        invalidPostalCode: 'Ugyldig postnummer',
        missingItems: 'Du må legge inn minst èn enkeltpakke, eller èn palle.',
        missingItemsNO: 'Du må legge inn minst èn enkeltpakke, eller velge en pall eller en postcontainer',
        notFound: 'Kunne ikke finne henteavtale',
        notFoundInfo: 'Har du skrevet inn rett hentekode?',
        notFoundInfo2: 'En gyldig hentekode starter alltid med bokstaven P og består av mellom 8 og 12 tegn.',
        notFoundPostalCode: 'Ukjent eller ugyldig postnummer',
        onlyDigitsAllowed: 'Kun tall er lov i dette feltet',
        postalCodeRange: 'Postnummer skal bestå av akkurat %{MIN} tall',
        required: 'Dette feltet er påkrevd',
        stringRange: 'Innskrevne data må være mellom %{MIN} og %{MAX} tegn',
        tooManyPackages: 'Du kan ikke bestille henting for mer enn %{MAX} enkeltpakker',
        tooManyPallets: 'Du kan ikke bestille henting for mer enn %{MAX} paller',
        warning: 'Advarsel',
        weightMissingToSmall: 'Vekt må være et positivt tallr',
        weightTooHigh: 'Totalvekt kan ikke overstige %{MAX} kg',
    },
    hero: {
        title: 'Bestill ny henting',
    },
    nav: {
        gotoHomePage: 'Gå til hjemmeside',
        selectLanguage: 'Velg språk',
    },
}
