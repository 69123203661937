export default {
    address: 'Adress',
    agreementCodePlaceholder: 'ex. P12345678',
    city: 'Postort',
    companyName: 'Namn',
    contactPerson: 'Kontaktperson',
    country: 'Land',
    email: 'E-post for bekräftelse',
    emailHelpText: 'Du kan ange flera e-postadresser genom att använda komma mellan adresserna',
    enterAgreementCode: 'Skriv in kod för upphämtning',
    packages: 'Paket',
    palletNO: 'Pall (max 1)',
    pallets: 'Antal pallar',
    phoneNumber: 'Telefonnummer',
    phoneNumberHelpText: 'Om chauffören behöver kontakt med den som ska överlämna sändningarna',
    pickupAddress: 'Hämtningsadress',
    pickupDate: 'Upphämtningsdatum',
    pickupDateInfoEarliest: 'Hämtning kan beställas tidigast för %{date}.',
    pickupDateInfoSameDay: 'För hämtning samma dag, se till att beställa innan %{time}.',
    pickupDateInfoTime: 'Hämtningstid för valt datum (%{date}) kommer normalt att vara mellan %{earliest} och %{latest}.',
    pickupInfo: 'Hämtningsinformation till chauffören',
    pickupInfoHelpText: 'Portkod, lunchstängt, c/o, trappor tex',
    pickupWhat: 'Vad kommer att hämtas?',
    pickupWhen: 'När är det klart för hämtning?',
    pickupWhere: 'Var ska den hämtas?',
    postContainer: 'Postcontainer (max 1)',
    postalCode: 'Postnummer',
    searchButton: 'Sök',
    submitButton: 'Beställ upphämtning',
    totalWeight: 'Totalvikt',
    weightInfoText: 'Maxvikt för ett singelpaket är 35 kg',
    confirmation: {
        confirmationMail: 'Bekräftelse skickad till',
        labelNotification: 'OBS: Alla föremål måste vara märka med fraktetikett när de hämtas.',
        multipleContainer: '%{number} behållare',
        multipleItemPickup: '%{number} enhet för hämtning',
        multiplePackage: '%{number} paket',
        multiplePallet: '%{number} pallar',
        packageAndBusinessPalletPickup: 'Package and Business Pallet Pickup',
        packageNumber: 'Ordernummer',
        pickup: 'Hämtas',
        pickupString: '%{formattedDate} mellan %{earliestTime} och %{latestTime}',
        pickupAddress: 'Adress för upphämtning',
        service: 'Tjänst',
        singleContainer: '1 container',
        singleItemPickup: 'Ett föremål för upphämtning',
        singlePackage: '1 paket',
        singlePallet: '1 pall',
        swedenWarning: 'Obs: i vissa geografiska områden garanteras inte önskad upphämtning samma dag även om beställningen görs senast innan 12:00.',
    },
    error: {
        couldNotReachSgWarning: 'Vi kan inte bekräfta tillgängliga hämtningsdatum för det valda postnumret! Du kan fortfarande välja ett hämtningsdatum, men tänk på att vi kanske inte kan hämta beställningen vid det valda datumet.',
        dateMustBeValid: 'Det valda datumet måste vara ett giltigt datum för hämtning',
        failedSubmit: 'Hämtningsorder misslyckades',
        internalError: 'Internt fel',
        internalErrorInfo: 'Något misslyckades vid försök att hämta den angivna avtalskoden. Försök igen eller kontakta kundsupport.',
        invalidEmail: 'Ogiltigt e-postformat',
        invalidPostalCode: 'Ogiltigt postnummer',
        missingItems: 'Du måste ställa in minst ett paket eller en pall',
        missingItemsNO: 'Du måste ställa in minst ett paket eller en postcontainer eller en pall',
        notFound: 'Kunde inte hitta hämtningsavtal',
        notFoundInfo2: 'En giltig hämtningsavtalskod börjar alltid med bokstaven P och består av mellan 8 och 12 tecken.',
        notFoundInfo: 'Skrev du rätt avtalskod?',
        notFoundPostalCode: 'Okänt/ogiltigt postnummer',
        onlyDigitsAllowed: 'Inmatning får endast vara siffror',
        postalCodeRange: 'Postnummer måste vara exakt %{MIN} siffror',
        required: 'Detta fält är obligatoriskt',
        stringRange: 'Måste vara mellan %{MIN} och %{MAX} tecken',
        tooManyPackages: 'Du får inte ha fler än %{MAX} paket',
        tooManyPallets: 'Du får inte ha fler än %{MAX} pallar',
        warning: 'Varning',
        weightMissingToSmall: 'Vikt krävs som ett positivt tal',
        weightTooHigh: 'Max totalvikt är %{MAX} kg',
    },
    hero: {
        title: 'Beställ ny upphämtning',
    },
    nav: {
        gotoHomePage: 'Gå til startsidan',
        selectLanguage: 'Ange språk',
    },
}
